require('./styles/style.scss');
import $ from 'jquery';
import TweenMax from "gsap/TweenMax";



$(document).ready(function(){
  
    // Появление центральных надписей
  TweenMax.from($("#lead_str3"), 0.5, {opacity: "0.1"});
  TweenMax.from($("#lead_str2"), 0.1, {opacity: "0.1", delay: 0.2});
  TweenMax.from($("#lead_str1"), 0.5, {top: "1em", opacity: "0.1", delay: 0.5});
  
  TweenMax.from($("#offer_str2"), 0.4, {top: "1em", opacity: "0.1", delay: 0.5});
  TweenMax.from($("#offer_str1"), 0.4, {top: "1em", opacity: "0.1", delay: 0.8});
  
  
  // Подчеркивание ссылок в середине
  $("#lead_str1").on("mouseover", function(e) {
      TweenMax.to($("#line1"), 0.3, {width: "100%"});
  });
  
  $("#lead_str1").on("mouseout", function(e) {
      TweenMax.to($("#line1"), 0.1, {width: 0});
  });
  
  $("#lead_str3").on("mouseover", function(e) {
      TweenMax.to($("#line2"), 0.3, {width: "100%"});
  });
  
  $("#lead_str3").on("mouseout", function(e) {
      TweenMax.to($("#line2"), 0.1, {width: 0});
  });
  
  
  //Заполнение полосок меню  
  var arr = [$("#inner_full_line1"), $("#inner_full_line2"), $("#inner_full_line3")];  
  $("#sandwich,#text_menu").on("mouseover", function(e) {
      TweenMax.killTweensOf(arr);
      TweenMax.staggerTo(arr, 0.2, {width: "100%"}, 0.05);
  });
  
  $("#sandwich,#text_menu").on("mouseout", function(e) {
      TweenMax.killTweensOf(arr);
      TweenMax.staggerTo(arr, 0.2, {width: 0}, 0.05);
  });
  
  //Раскрытие и скрытие меню
  $("#sandwich,#text_menu").on("mouseup", function(e) {
      TweenMax.killAll();
      if(!$("#menu_block").hasClass('active')) {
        menu_show();
        $("#menu_block").addClass('active');
    } else {
        menu_hide();
        $("#menu_block").removeClass('active');
    }
  });
  
  // esc
    $(document).keyup(function(e) {
        if($("#menu_block").hasClass('active')) {
            if (e.keyCode === 27) {
                menu_hide();
                $("#menu_block").removeClass('active');
            }   
        }
    });
    
    $(document).on('scroll', function(e) {
        if($("#menu_block").hasClass('active')) {
                menu_hide();
                $("#menu_block").removeClass('active');
        }
    });
  
    
  function menu_show() {
      $("#body_wrapper").removeClass("bg_animated").addClass("bg_overlay");
      TweenMax.to($("#menu_block"), 0.3, {left: -180});
      TweenMax.to($("body"), 0.3, {left: 180});
      TweenMax.to($("#menu"), 0.3, {marginLeft: 90});
      TweenMax.killTweensOf($("#inner_full_line2"));
      TweenMax.to($("#inner_line2"), 0.7, {opacity: 0});
      TweenMax.to($("#inner_line1"), 0.7, {rotation: 45, top:-2});
      TweenMax.to($("#inner_line3"), 0.7, {rotation: -45, top:11});
      TweenMax.to($("#text_menu_inner_open"), 0.7, {opacity: 0});
      TweenMax.to($("#text_menu_inner_close"), 0.7, {opacity: 1, delay: 0.2});
      TweenMax.staggerFrom($(".menu_list_item").slice(1), 0.25, {marginLeft: -250, opacity: 0}, 0.2);
      TweenMax.staggerFrom($(".menu_social a").slice(1), 0.2, {marginBottom: -100, opacity: 0}, 0.1);
      TweenMax.from($("#menu_contact"), 0.2, {opacity: 0, delay: 1});
  };
  
  function menu_hide() {
      $("#body_wrapper").addClass("bg_animated").removeClass("bg_overlay");
      TweenMax.to($("#menu_block"), 0.3, {left: -250});
      TweenMax.to($("#menu"), 0.3, {marginLeft: 30});
      TweenMax.to($("#inner_line2"), 0.7, {opacity: 1});
      TweenMax.to($("#inner_line1"), 0.7, {rotation: 0, top:0});
      TweenMax.to($("#inner_line3"), 0.7, {rotation: 0, top:10});
      TweenMax.to($("#text_menu_inner_close"), 0.7, {opacity: 0});
      TweenMax.to($("#text_menu_inner_open"), 0.7, {opacity: 1, delay: 0.3});
      TweenMax.to($("#menu_block"), 0.3, {left: -250});
      TweenMax.to($("body"), 0.3, {left: 0});
  };
  
  // Меню внутри
  
  $("#menu_list a").on("mouseover", function(e) {
      if(!$(this).hasClass('current')) {
          TweenMax.to($(this), 0, {backgroundPositionY: 10});
          TweenMax.to($(this), 0.2, {marginLeft: 20, color: '#000000'});
      }
  });
  
  $("#menu_list a").on("mouseout", function(e) {
      if(!$(this).hasClass('current')) {
          TweenMax.to($(this), 0, {backgroundPositionY: -34});
          TweenMax.to($(this), 0.15, {marginLeft: 0, color: '#c0bdbf'});

      }
  });
  
  
  // Иконки соцсетей
  $(".menu_social a").on("mouseover", function(e) {
      TweenMax.to($(this).children('.social_inner_bg'), 0.3, {width: '100%'});
  });
  $(".menu_social a").on("mouseout", function(e) {
      TweenMax.to($(this).children('.social_inner_bg'), 0.1, {width: 0});
  });
  
  

});